<!-- <div id="preloader" class="tlp-preloader">
		<div class="animation-preloader">
			<div class="tlp-spinner"></div>
			<img src="../../assets/media/preloader.png"  alt="Preloader">
		</div>
	</div> -->
<div id="wrapper" class="wrapper">
	<a href="#main_content" data-type="section-switch" class="return-to-top">
		<i class="fas fa-angle-double-up"></i>
	</a>

	<div id="main_content">
		<!--=====================================-->
		<section class="main-banner-wrap-layout1 bg-gradient-layout1 has-animation">
			<div class="container text-center">
				<h2 style="color: #fff;">Contact Us</h2>
				<p style="color: #fff;font-size: 18px;">
					Please fill the form and our engagement manager will get in touch with you, shortly!</p>
			</div>
			<div class="container">
				<!-- <ul class="animated-figure">
					<li>
						<div class="translate-left-75 opacity-animation transition-200 transition-delay-10">
							<svg width="678px" height="575px">
					<defs>
						<linearGradient id="shape-gradient1" x1="0%" x2="50%" y1="86.603%" y2="0%">
						  	<stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.21" />
								<stop offset="100%" stop-color="rgb(104,2,198)" stop-opacity="0.21" />
						</linearGradient>
					</defs>
					<path fill="url(#shape-gradient1)"
					 d="M8.774,72.637 C24.335,32.328 64.598,1.818 107.846,0.977 C153.529,0.088 193.846,29.308 228.367,59.201 C262.888,89.095 297.864,122.295 342.435,132.337 C395.990,144.404 451.255,120.277 506.077,123.221 C581.185,127.255 647.967,185.940 669.010,258.060 C690.054,330.177 668.236,411.294 619.856,468.811 C571.474,526.325 499.170,561.071 424.701,571.631 C341.448,583.438 251.586,564.649 188.145,509.531 C153.304,479.260 128.229,437.550 117.609,392.681 C109.183,357.083 116.362,318.069 105.755,283.272 C94.490,246.320 59.108,232.772 35.167,205.579 C3.816,169.968 -8.566,117.557 8.774,72.637 Z"/>
				</svg>
						</div>
					</li>
					<li>
						<div class="translate-right-75 opacity-animation transition-200 transition-delay-500">
							<svg width="478px" height="406px">
					<defs>
						<linearGradient id="shape-gradient2" x1="0%" x2="50%" y1="86.603%" y2="0%">
						  	<stop offset="0%" stop-color="rgb(82,63,255)" stop-opacity="0.21" />
								<stop offset="100%" stop-color="rgb(141,34,240)" stop-opacity="0.21" />
						</linearGradient>
					</defs>
					<path fill="url(#shape-gradient2)"
					 d="M57.512,28.844 C41.937,43.215 29.659,61.585 21.596,80.726 C10.238,107.690 5.415,136.934 2.113,166.013 C-8.559,260.013 20.528,368.419 121.400,397.331 C189.062,416.723 262.433,396.867 324.343,368.126 C415.304,325.900 543.497,194.454 436.347,96.738 C378.745,44.207 285.697,64.026 217.602,35.418 C185.699,22.011 155.662,-2.674 119.276,0.267 C95.545,2.187 74.555,13.118 57.512,28.844 Z"/>
				</svg>
						</div>
					</li>
				</ul> -->
				<div class="container-fluid">
					<div class="row">
						<!-- <div class="col-lg-6 col-12 order-lg-2 d-flex align-items-center justify-content-center">
							<div class="">
								<div class="main-banner-box-layout1">
									<div class="mapweb">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.4963632599233!2d77.1433302144038!3d28.494710297057583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1fc5c305dee9%3A0x41de70881c4d8c60!2sMonkhub!5e0!3m2!1sen!2sin!4v1620480569492!5m2!1sen!2sin" width="550" height="445" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
									</div>
                                    
									<div class="mapmob" style="margin-top: 25px;">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.4963632599233!2d77.1433302144038!3d28.494710297057583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1fc5c305dee9%3A0x41de70881c4d8c60!2sMonkhub!5e0!3m2!1sen!2sin!4v1620480569492!5m2!1sen!2sin" width="300" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
									</div>
								</div>
							</div>
						</div> -->
						<div class="col-lg-6 order-lg-1 col-12 compress-left-side d-flex align-items-center">
							<div class="main-banner-box-layout1">
								<div class="contact-wrap-layout1 forbox">
									<div class="contact-box-layout1 has-animation">
										<form (ngSubmit)="f.form.valid && contactForm()" #f="ngForm">
											<div class="row">
												<div class="col-lg-12">
													<div class="form-group">
														<input type="text" class="form-control" id="fullname"
															placeholder="Your Name *" name="fullname"
															[(ngModel)]="contactDetails.fullname" #fullname="ngModel"
															[ngClass]="{ 'is-invalid': f.submitted && fullname.invalid }"
															data-toggle="tooltip" data-trigger="hover"
															data-placement="top" required data-original-title=""
															title="">
														<div *ngIf="f.submitted && fullname.invalid"
															class="invalid-feedback">
															<div *ngIf="fullname">Name is required</div>
															
														</div>
													</div>
												</div>
												<div class="col-lg-12">
													<div class="form-group">
														<input type="email" class="form-control"
															placeholder="Your Email *" name="email"
															[(ngModel)]="contactDetails.email" #email="ngModel"
															[ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
															data-toggle="tooltip" data-trigger="hover"
															data-placement="top" required data-original-title=""
															title="">
														<div *ngIf="f.submitted && email.invalid"
															class="invalid-feedback">
															<div *ngIf="email">Email is required</div>
														</div>
													</div>
												</div>
												<div class="col-lg-12">
													<div class="form-group">
														<input  type="tel" class="form-control"
															placeholder="Your Number *" name="phone"
															[(ngModel)]="contactDetails.phone" #phone="ngModel"
															[ngClass]="{ 'is-invalid': f.submitted && phone.invalid }"
															data-toggle="tooltip" data-trigger="hover"
															data-placement="top" required data-original-title=""
															title="">
														<div *ngIf="f.submitted && phone.invalid"
															class="invalid-feedback">
															<div *ngIf="phone">Number is required</div>
															
														</div>
													</div>
												</div>
												<div class="col-lg-12">
													<div class="form-group">
														<input type="text" class="form-control" id="source"
															placeholder="Enter Message" name="source"
															[(ngModel)]="contactDetails.source">
													</div>
												</div>
											</div>
											<div class="form-actions">
												<button type="submit" class="btn_mythree"
													style="width: 100%;cursor: pointer;">
													Submit <i class="fa fa-spinner fa-spin" *ngIf="imgloading"></i>
												</button>
											</div>
										</form>

									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-12 order-lg-2 d-flex align-items-center justify-content-center">
							<div class="">
								<div class="main-banner-box-layout1">
									<img src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1696837972942-Lets_talk.png" class="img-wd" alt="Illustration">
									<!--<div class="mapweb">
										<iframe
											src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.4963632599233!2d77.1433302144038!3d28.494710297057583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1fc5c305dee9%3A0x41de70881c4d8c60!2sMonkhub!5e0!3m2!1sen!2sin!4v1620480569492!5m2!1sen!2sin"
											width="550" height="445" style="border:0;" allowfullscreen=""
											loading="lazy"></iframe>
									</div>

									<div class="mapmob" style="margin-top: 25px;">
										<iframe
											src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.4963632599233!2d77.1433302144038!3d28.494710297057583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1fc5c305dee9%3A0x41de70881c4d8c60!2sMonkhub!5e0!3m2!1sen!2sin!4v1620480569492!5m2!1sen!2sin"
											width="300" height="300" style="border:0;" allowfullscreen=""
											loading="lazy"></iframe>
									</div>-->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</div>
<script>(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"56360281"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");</script>