import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Contact } from '../../../models/contact-model/contact.model';
import { ContactServiceService } from '../../../services/contact-service/contact-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
  proDetails = this.contactUsModel.projectDetails;
  imgloading: boolean = false;
  fileData!: File;
  errorFileSize: string = '';
  formNames = this.route.snapshot.params.id;
  successMessage: string = '';
  constructor(private router: Router,
    private route: ActivatedRoute, private contactUsModel: Contact,
    private contactService: ContactServiceService,
    private renderer: Renderer2,
    private toastrService: ToastrService) { }

  public isChecked = true;
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    if (this.fileData.size > 1048576) {
      this.errorFileSize = 'Congratulation!! Data Successfully Submitted'
      this.toastrService.success(this.errorFileSize, 'Success', {
        timeOut: 3000,
      });
    }

  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    this.renderer.appendChild(document.head, script);
    this.animation();
    this.getCompaignData();

    this.proDetails.timeZone="Choose Your Time Zone";
    this.proDetails.timeSlot="Choose Your Time Slot";
    this.proDetails.budget="Choose Your Budget";
    this.proDetails.category="Choose Your Category";
    this.proDetails.formName = this.formNames;
  }
  animation() {
    if (!!window.IntersectionObserver) {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add("active-animation");
            //entry.target.src = entry.target.dataset.src;
            observer.unobserve(entry.target);
          }
        });
      }, {
        rootMargin: "0px 0px -100px 0px"
      });
      document.querySelectorAll('.has-animation').forEach(block => {
        observer.observe(block)
      });
    } else {
      document.querySelectorAll('.has-animation').forEach(block => {
        block.classList.remove('has-animation')
      });
    }
  }

  getCompaignData(){
    this.route.queryParams.subscribe(res => {
      if(res.utm_source != undefined){
        this.proDetails.utmSource = res.utm_source;
      }
      if(res.utm_medium != undefined){
        this.proDetails.utmMedium = res.utm_medium;
      }
      if(res.utm_campaign != undefined){
        this.proDetails.utmCampaign = res.utm_campaign;
      }
      if(res.utm_term != undefined){
        this.proDetails.utmTerm = res.utm_term;
      }
      if(res.utm_content != undefined){
        this.proDetails.utmContent = res.utm_content;
      }
    })
  }


  projectDetailsPost() {
    
    if ("personDetails" in localStorage) {
      const perDetails = JSON.parse(localStorage.getItem('personDetails') || '{}');
      this.proDetails.consultationId = perDetails.consultationId;
    } else {
      this.proDetails.consultationId = 0;
    }
    if (this.isChecked == false) {
      this.proDetails.statusNDA = 'No';
    } else if (this.isChecked == true) {
      this.proDetails.statusNDA = 'Yes';
    }

    const formData = new FormData();
    formData.append('formName', this.proDetails.formName);
    if ((typeof (this.fileData) == 'undefined') == true) {
      console.log('0');
    }
    else {
      formData.append('fileName', this.fileData);
    }
    formData.append('utmSource', this.proDetails.utmSource);
    formData.append('utmMedium', this.proDetails.utmMedium);
    formData.append('utmCampaign', this.proDetails.utmCampaign);
    formData.append('utmTerm', this.proDetails.utmTerm);
    formData.append('utmContent', this.proDetails.utmContent);
    formData.append('category', this.proDetails.category);
    formData.append('budget', this.proDetails.budget);
    formData.append('describeProject', this.proDetails.describeProject);
    formData.append('date', this.proDetails.date);
    formData.append('timeSlot', this.proDetails.timeSlot);
    formData.append('timeZone', this.proDetails.timeZone);
    formData.append('statusNDA', this.proDetails.statusNDA);
    formData.append('consultationId', this.proDetails.consultationId.toString());
    this.contactService.projectDetails(formData).subscribe(
      (data: any) => {
        this.successMessage = 'Thanks for sharing the details'
        this.toastrService.success(this.successMessage, 'Success', {
          timeOut: 100,
          progressBar: true,
          closeButton: true,
          easeTime: 200,
          extendedTimeOut: 500,
        });
        if (this.isChecked == false) {
          this.thankyouRedirect();
        } else if (this.isChecked == true) {
          this.pageToRedirect();
        }
      },
      error => {
        console.log(error);
        this.errorFileSize = 'Error Coming'
        this.toastrService.error(this.errorFileSize, 'Error', {
          timeOut: 3000,
        });
      }
    );
  }
  pageToRedirect() {
    const id = this.proDetails.formName
    this.router.navigate(['/us/nda-details/' + id]);
  }
  thankyouRedirect() {
    this.router.navigate(['/us/thank-you/']);
  }
}
