import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GetFreeQuoteComponent } from './get-free-quote/get-free-quote.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NdaDetailsComponent } from './nda-details/nda-details.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
// import { ProjectDetailsComponent } from './project-details/project-details.component';


const routes: Routes = [
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'nda-details/:id', component: NdaDetailsComponent },
  { path: 'project-details/:id/:pageText', component: ProjectDetailsComponent },
  { path: 'get-free-quote', component: GetFreeQuoteComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactUsRoutingModule { }
