import { Component } from '@angular/core';

@Component({
  templateUrl: '404.component.html',
  styleUrls: ['404.component.css']
  
})
export class P404Component {

  constructor() { }

}
