import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  mobilenav: boolean = true;
  webnav: boolean = true;
  isShown = false;
  navbarOpen = false;
  navbarsubOpen = false;
  navthirsubbar = false;
  navfourthsubbar = false;
  navMvpsubbar=false;
  hideSubmenu = true; // Added property

  constructor() { }

  ngOnInit(): void {
    this.menufunction();
    this.hideSubmenu=true
    setInterval(() => {
      this.hideSubmenu = true; // Set hideSubmenu to true every 1ms
     
    }, 1); // 1 millisecond interval
  
  }

  menufunction() {
    if (window.screen.width < 768) {
      this.mobilenav = true;
      this.webnav = false;
    } else {
      this.mobilenav = false;
      this.webnav = true;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.menufunction();
  }
  hideactivemenu() {
    this.isShown = !this.isShown;
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    this.navbarsubOpen = false;
    this.navthirsubbar = false;
    this.navfourthsubbar = false;
    this.navMvpsubbar = false;
  }

  toggleSubBar() {
    this.navbarsubOpen = !this.navbarsubOpen;
  }

  togglethirdSubBar() {
    this.navthirsubbar = !this.navthirsubbar;
  }

  togglefourthSubBar() {
    this.navfourthsubbar = !this.navfourthsubbar;
  }
  toggleMvpSubBar() {
    this.navMvpsubbar = !this.navMvpsubbar;
  }

  hidemenu() {
    this.hideSubmenu = false;
  }

  showmenu() {
    this.hideSubmenu = false;
  }
}
