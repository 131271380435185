import { Component, OnInit , Input , DoCheck } from '@angular/core';
import { interval } from 'rxjs';
import * as $ from 'jquery';

@Component({
  selector: 'app-header-us',
  templateUrl: './header-us.component.html',
  styleUrls: ['./header-us.component.scss']
})
export class HeaderUsComponent implements OnInit, DoCheck {
  mobilenav: boolean = true;
  webnav: boolean = true;
  isShown = false;
  navbarOpen = false;
  navbarsubOpen = false;
  navthirsubbar = false;
  navfourthsubbar = false;
  showmenu = true;


  constructor() { }

  ngOnInit(): void {
    this.menufunction();
    // $('.nav-link').click(() => $('.collapse').collapse('toggle'));
  }
  ngDoCheck(): void {
    interval(1000).subscribe(() => {
      this.showmenu = true;
    });
  }

  menufunction(){
    if (window.screen.width < 768) {
      this.mobilenav = true;
      this.webnav = false
    } else {
      this.mobilenav = false;
      this.webnav = true
    }
}
hideactivemenu(){
  this.isShown = !this.isShown;
}
toggleNavbar() {
  this.navbarOpen = !this.navbarOpen;
  this.navbarsubOpen = false;
  this.navthirsubbar = false;
  this.navfourthsubbar = false;
}
toggleSubBar() {
  this.navbarsubOpen = !this.navbarsubOpen;
}
togglethirdSubBar() {
  this.navthirsubbar = !this.navthirsubbar;
}
togglefourthSubBar(){
this.navfourthsubbar =!this.navfourthsubbar
}

hidemenu(){
  this.showmenu = false;
}
}
