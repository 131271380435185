import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser'
import { CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FooterComponent } from './footer/footer.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
// import { HomeUsComponent } from './us/home-us/home-us.component';
// import { BlockchainUsComponent } from './us/our-services/blockchain-us/blockchain-us.component';
import { AppDevelopmentUsComponent } from './us/our-services/app-development-us/app-development-us.component';
// import { WebDevelopmentUsComponent } from './us/our-services/web-development-us/web-development-us.component';
// import { GameDevelopmentUsComponent } from './us/our-services/game-development-us/game-development-us.component';
// import { AiMlUsComponent } from './us/our-services/ai-ml-us/ai-ml-us.component';
// import { BlockchainDefiUsComponent } from './us/our-services/blockchain-services/blockchain-defi-us/blockchain-defi-us.component';
// import { BlockchainEthereumUsComponent } from './us/our-services/blockchain-services/blockchain-ethereum-us/blockchain-ethereum-us.component';
// import { BlockchainExchangeUsComponent } from './us/our-services/blockchain-services/blockchain-exchange-us/blockchain-exchange-us.component';
// import { BlockchainGameUsComponent } from './us/our-services/blockchain-services/blockchain-game-us/blockchain-game-us.component';
// import { BlockchainIcoUsComponent } from './us/our-services/blockchain-services/blockchain-ico-us/blockchain-ico-us.component';
// import { BlockchainNftUsComponent } from './us/our-services/blockchain-services/blockchain-nft-us/blockchain-nft-us.component';
// import { BlockchainSmartContractAuditUsComponent } from './us/our-services/blockchain-services/blockchain-smart-contract-audit-us/blockchain-smart-contract-audit-us.component';
// import { BlockchainStableCoinUsComponent } from './us/our-services/blockchain-services/blockchain-stable-coin-us/blockchain-stable-coin-us.component';
// import { BlockchainTokenizationUsComponent } from './us/our-services/blockchain-services/blockchain-tokenization-us/blockchain-tokenization-us.component';
// import { BlockchainWhitePaperUsComponent } from './us/our-services/blockchain-services/blockchain-white-paper-us/blockchain-white-paper-us.component';
// import { BlockchainWalletUsComponent } from './us/our-services/blockchain-services/blockchain-wallet-us/blockchain-wallet-us.component';
// import { BlockchainStoUsComponent } from './us/our-services/blockchain-services/blockchain-sto-us/blockchain-sto-us.component';
// import { ContactUsUsComponent } from './us/contact-us/contact-us-us/contact-us-us.component';
// import { GetFreeQuoteUsComponent } from './us/contact-us/get-free-quote-us/get-free-quote-us.component';
// import { MainFormUsComponent } from './us/contact-us/main-form-us/main-form-us.component';
// import { NdaDetailsUsComponent } from './us/contact-us/nda-details-us/nda-details-us.component';
// import { ProjectDetailsUsComponent } from './us/contact-us/project-details-us/project-details-us.component';
// import { AboutUsUsComponent } from './us/about-us-us/about-us-us.component';
// import { OurWorkUsComponent } from './us/our-work-us/our-work-us.component';
// import { ThankYouUsComponent } from './us/thank-you-us/thank-you-us.component';
import { HeaderUsComponent } from './header-us/header-us.component';
// import { FooterUsComponent } from './footer-us/footer-us.component';
// import { ArVrMrUsComponent } from './us/our-services/ar-vr-mr-us/ar-vr-mr-us.component';
// import { ExplainerVideoUsComponent } from './us/our-services/explainer-video-us/explainer-video-us.component';
// import { GraphicDesignUsComponent } from './us/our-services/graphic-design-us/graphic-design-us.component';
// import { IotUsComponent } from './us/our-services/iot-us/iot-us.component';
// import { UiUxUsComponent } from './us/our-services/ui-ux-us/ui-ux-us.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

// import { HomeMainFormUsComponent } from './us/contact-us/home-main-form-us/home-main-form-us.component';
import { P404Component } from './error/404.component';
// import { NftMarketplaceDevelopmentUsComponent } from './us/our-services/blockchain-services/blockchain-nft-subservices/nft-marketplace-development-us/nft-marketplace-development-us.component';
// import { NftExchangePlatformUsComponent } from './us/our-services/blockchain-services/blockchain-nft-subservices/nft-exchange-platform-us/nft-exchange-platform-us.component';
// import { UnityGameDevelopmentUsComponent } from './us/our-services/game-development-services/unity-game-development-us/unity-game-development-us.component';
// import { CareersUsComponent } from './us/careers-us/careers-us.component';
// import { CryptodailyUsComponent } from './us/cryptodaily-us/cryptodaily-us.component';
import { JobDetailsComponent } from './app-in/job-details/job-details.component';
// import { JobDetailsUsComponent } from './us/dialog/job-details-us/job-details-us.component';
import { PrivacyPolicyComponent } from './app-in/privacy-policy/privacy-policy.component';
import { AndroidAppDevelopmentUsComponent } from './us/our-services/app-development-us/android-app-development-us/android-app-development-us.component';
import { HybridAppDevelopmentUsComponent } from './us/our-services/app-development-us/hybrid-app-development-us/hybrid-app-development-us.component';
import { IosAppDevelopmentUsComponent } from './us/our-services/app-development-us/ios-app-development-us/ios-app-development-us.component';
import { LinkService } from './linkservice.service';
// import { MetaverseusComponent } from './us/our-services/metaverse-us/metaverse-us.component';
// import { ClientUsComponent } from './us/clients/client-us/client-us.component';
import { WhyChooseUsInComponent } from './in/our-services/blockchain/common-components/why-choose-us-in/why-choose-us-in.component';
import { OurProcessInComponent } from './in/our-services/blockchain/common-components/our-process-in/our-process-in.component';
import { BlockchainNetworksInComponent } from './in/our-services/blockchain/common-components/blockchain-networks-in/blockchain-networks-in.component';

import { SharedModule } from './shared/shared.module';
import { ContactUsModule } from './app-in/contact-us/contact-us.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// import { ArVrMrComponent } from './in/our-services/ar-vr-mr/ar-vr-mr.component';

@NgModule({
  entryComponents: [
    JobDetailsComponent,
    // JobDetailsUsComponent
  ],
  declarations: [
    P404Component,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    // HomeUsComponent,
    // BlockchainUsComponent,
    AppDevelopmentUsComponent,
    // WebDevelopmentUsComponent,
    // GameDevelopmentUsComponent,
    // AiMlUsComponent,
    // BlockchainDefiUsComponent,
    // BlockchainEthereumUsComponent,
    // BlockchainExchangeUsComponent,
    // BlockchainGameUsComponent,
    // BlockchainIcoUsComponent,
    // BlockchainNftUsComponent,
    // BlockchainSmartContractAuditUsComponent,
    // BlockchainStableCoinUsComponent,
    // BlockchainTokenizationUsComponent,
    // BlockchainWhitePaperUsComponent,
    // BlockchainWalletUsComponent,
    // BlockchainStoUsComponent,
    // ContactUsUsComponent,
    // GetFreeQuoteUsComponent,
    // MainFormUsComponent,
    // NdaDetailsUsComponent,
    // ProjectDetailsUsComponent,
    // AboutUsUsComponent,
    // OurWorkUsComponent,
    // ThankYouUsComponent,
    HeaderUsComponent,
    // FooterUsComponent,
    // ArVrMrUsComponent,
    // ExplainerVideoUsComponent,
    // GraphicDesignUsComponent,
    // IotUsComponent,
    // UiUxUsComponent,
    // HomeMainFormUsComponent,
    // NftMarketplaceDevelopmentUsComponent,
    // NftExchangePlatformUsComponent,
    // UnityGameDevelopmentUsComponent,
    // CareersUsComponent,
    // CryptodailyUsComponent,
    PrivacyPolicyComponent,
    AndroidAppDevelopmentUsComponent,
    HybridAppDevelopmentUsComponent,
    IosAppDevelopmentUsComponent,
    // MetaverseusComponent,
    // ClientUsComponent,
    WhyChooseUsInComponent,
    OurProcessInComponent,
    BlockchainNetworksInComponent,  
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    FormsModule,
    SharedModule,
    // AppInModule,
    ContactUsModule,
    CommonModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    HttpClientModule,
    NgxIntlTelInputModule,
    ToastrModule.forRoot({
      progressBar: true,
      closeButton:true,
      // disableTimeOut:false
     }),

  ],
  // exports: [
  //   ClutchComponent,
  //   ClientInComponent
  // ],
  providers: [Title,{provide: LocationStrategy, useClass: PathLocationStrategy},LinkService],
  bootstrap: [AppComponent]
})
export class AppModule { }
