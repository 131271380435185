import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactUsRoutingModule } from './contact-us-routing.module';
import { GetFreeQuoteComponent } from './get-free-quote/get-free-quote.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeMainFormComponent } from './home-main-form/home-main-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MainFormComponent } from './main-form/main-form.component';
import { NdaDetailsComponent } from './nda-details/nda-details.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { LetsDiscussComponent } from './lets-discuss/lets-discuss.component';




@NgModule({
  declarations: [
    ContactUsComponent,
    HomeMainFormComponent,
    GetFreeQuoteComponent,
    MainFormComponent,
    NdaDetailsComponent,
    ProjectDetailsComponent,
    LetsDiscussComponent,
   
  ],
  imports: [
    CommonModule,
    FormsModule,
    ContactUsRoutingModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
  ],
  exports:[
    HomeMainFormComponent,
    MainFormComponent,
    LetsDiscussComponent,
  ]
})
export class ContactUsModule { }
