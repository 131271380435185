<header class="sticky-on">
  <div id="topbar-wrap" *ngIf="webnav">
     <div class="topbar-layout4">
        <div class="container">
           <div class="row no-gutters d-flex align-items-center">
              <div class="col-lg-6 d-flex justify-content-start">
                 <ul class="topbar-menu">
                    <li><img src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1621325511748-Phone.png" alt="">&nbsp;<a class="colaredw" href="tel:+1 12342173520"> +91 9090080015</a></li>
                    <li><i class="fas fa-envelope"></i><a class="colaredw" href="mailto:connect@monkhub.com">connect@monkhub.com</a></li>
                 </ul>
              </div>
              <div class="col-lg-6 d-flex justify-content-end">
                 <ul class="topbar-social">
                    <li class="single-item">  <a href="https://api.whatsapp.com/send?phone=919090080015&amp;text=Hello%20Monkhub" rel="nofollow noopener" target="_blank"><img width="32" height="32" src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1621326113656-logos_whatsapp.png"></a></li>
                    <li class="single-item">   <a href="https://join.skype.com/invite/uLJJgZPpELxM" rel="nofollow noopener" target="_blank"><img width="32" height="32" src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1678360103627-skype-icon.png"></a></li>
                    <li class="single-item">  <a href="https://msng.link/o/?+91%209090080015=tg" rel="nofollow noopener" target="_blank"><img width="32" height="32" src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1678360213496-Telegram.png"></a></li>
                    <li class="single-item">  <a href="https://www.linkedin.com/company/monkhub/" rel="nofollow noopener" target="_blank"><img width="32" height="32" src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1678359252431-Linked1.png"></a></li>
                 </ul>
              </div>
           </div>
        </div>
     </div>
  </div>
  <div id="sticky-placeholder"></div>
  <div id="navbar-wrap" *ngIf="webnav" class="navbar-wrap">
     <div class="navbar-layout1">
        <div class="container">
           <div class="row no-gutters d-flex align-items-center position-relative">
              <div class="col-lg-2 d-flex justify-content-start">
                 <div class="temp-logo text-center">
                    <a routerLink='/in' class="default-logo">
                    <img src="../../assets/logo/footerlogo.png" alt="logo" class="img-fluid">
                    </a>
                    <a routerLink='/in' class="sticky-logo">
                    <img src="../../assets/logo/logo.png" alt="logo" class="img-fluid">
                    </a>
                 </div>
              </div>
              <div class="col-lg-8 d-flex justify-content-end possition-static">
                 <nav id="dropdown" class="template-main-menu">
                    <ul style="margin: 0px;">
                       <li class="position-static d-none d-lg-block">
                          <a routerLink='/in'>Home</a>
                       </li>
                       <li class="d-block d-lg-none">
                          <a routerLink='/in'>Home</a>
                       </li>
                       <li class="position-static d-lg-block">
                          <a href="">Services</a>
                          <div class="mega-menu-container" *ngIf="hideSubmenu">
                             <div class="row">
                                <div class="col-lg-4">
                                   <div class="mega-menu-box">
                                      <div class="mega-menu-title">
                                         <h3 class="item-title itemet">Technology</h3>
                                         <ul class="item-title">
                                          <li><a routerLink='/us/our-services/app-development' (click)="hidemenu()"><span>App Development</span></a></li>
                                        
                                          <li><a routerLink='/us/our-services/metaverse' (click)="hidemenu()"><span>Metaverse Development</span></a></li>
                                            <li><a routerLink='/us/our-services/blockchain' (click)="hidemenu()"><span>Blockchain Development</span></a></li>
                                            <li><a routerLink='/us/our-services/game-development' (click)="hidemenu()"><span>Game Development</span></a></li>
                                            <li><a routerLink='/us/our-services/ar-vr-mr' (click)="hidemenu()"><span>AR/VR/MR</span></a></li>
                                            <li><a routerLink='/us/our-services/web-development' (click)="hidemenu()"><span>Web Development</span></a></li>
                                            <li><a routerLink='/us/our-services/ai-ml' (click)="hidemenu()"><span>AI/ML & Data Sciences</span></a></li>
                                            <li><a routerLink='/us/our-services/iot' (click)="hidemenu()"><span>IoT & Robotics</span></a></li>
                                            <li><a routerLink='/us/our-services/web-3' (click)="hidemenu()"><span>Web 3.0 Development</span></a></li>
                                           
                                         </ul>
                                      </div>
                                   </div>
                                </div>
                                <div class="col-lg-4">
                                   <div class="mega-menu-box">
                                      <div class="mega-menu-title">
                                         <h3 class="item-title itemet">Design</h3>
                                         <ul class="item-title">
                                            <li><a routerLink='/us/our-services/ui-ux' (click)="hidemenu()"><span>UI/UX</span></a></li>
                                            <li><a routerLink='/us/our-services/graphic-design' (click)="hidemenu()"><span>Graphic Design</span></a></li>
                                            <li><a routerLink='/us/our-services/explainer-video' (click)="hidemenu()"><span>Explainer Video</span></a></li>
                                            <li><a routerLink='/us/our-services/3d-art' (click)="hidemenu()"><span>3D ART</span></a></li>
                                         </ul>
                                      </div>
                                   </div>
                                </div>

                                <div class="col-lg-4">
                                 <div class="mega-menu-box">
                                    <div class="mega-menu-title">
                                       <h3 class="item-title itemet">Product</h3>
                                       <ul class="item-title">
                                          <li><a routerLink='/us/our-services/mvp-development' (click)="hidemenu()"><span>MVP Development</span></a></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                             </div>
                          </div>
                        </li>
                       <li>
                          <a routerLink='/us/portfolio'><span>Our Work</span></a>
                       </li>
                       <!-- <li>
                        <a href='https://monkhub.com/blog/' target="_blank"><span>Blog</span></a>
                       </li> -->
                       <li>
                          <a routerLink='/us/about-us'><span>About Us</span></a>
                       </li>
                       <li>
                        <a routerLink='/us/careers'><span>Careers</span></a>
                       </li>
                       <!-- <li>
                        <a routerLink='/us/blockchain-cryptodaily'><span>Crypto Daily</span></a>
                       </li> -->
                    </ul>
                 </nav>
              </div>
              <div class="col-lg-2 d-flex justify-content-end">
                 <ul style="margin: 0px;" class="header-action-items">
                    <li class="single-item mr-2">
                       <a routerLink='/us/get-free-quote'  class="item-btn btn-ghost btn-light"><span>Get Free Quote</span></a>
                    </li>
                  </ul>
              </div>
           </div>
        </div>
     </div>
  </div>
  <div *ngIf="mobilenav" id="mynavmenu">
     <div class="mean-container">
        <div class="my-navbar navweb">
           <div class="container-fluid">
              <nav class="navbar navbar-expand-md  navbar-light">
                 <!-- Brand -->
                 <a class="navbar-brand" [routerLink]="['/']">
                 <img src="../../assets/logo/logo.png" alt="image" style="width:80%">
                 </a>
                 <!-- Toggler/collapsibe Button -->
                 <button class="navbar-toggler" type="button" data-toggle="collapse"  (click)="toggleNavbar()">
                 <span class="navbar-toggler-icon"></span>
                 </button>
                 <!-- Navbar links -->
                 <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" >
                    <ul class="navbar-nav">
                       <!-- <li class="nav-item">
                          <a role="button" (click)="func('value')" class="nav-link poin">
                              Our Values
                              <i class='bx bx-chevron-down'></i>
                          </a>
                          </li> -->
                       <li class="navmre">
                          <a routerLink='/in' (click)="toggleNavbar()">Home</a>
                       </li>
                       <li class="nav-item">
                          <div class="faq-box">
                             <div id="accordion">
                                <div class="card single-item">
                                   <div class="card-header item-nav">
                                      <a data-toggle="collapse" data-parent="#accordion" (click)="toggleSubBar()">Services</a>
                                   </div>
                                   <div class="collapse item-content-wrap" [ngClass]="{ 'show': navbarsubOpen }">
                                      <div class="card single-item">
                                         <div class="card-header item-nav" >
                                            <a data-toggle="collapse"  class="subthirdmenu" data-parent="#accordion" (click)="togglethirdSubBar()">Technology</a>
                                         </div>
                                         <div class="collapse item-content-wrap" [ngClass]="{ 'show': navthirsubbar }">
                                            <div class="card-body item-content">
                                               <ul class="dropdown-menu-col-1 subhead">
                                                <li><a routerLink='/us/our-services/app-development' (click)="toggleNavbar()"><span>App Development</span></a></li>
                                                <!-- <li><a routerLink='/us/our-services/mvp-development' (click)="toggleNavbar()"><span>MVP Development</span></a></li> -->
                                                <li><a routerLink='/us/our-services/metaverse' (click)="toggleNavbar()"><span>Metaverse Development</span></a></li>
                                                  <li><a routerLink='/us/our-services/blockchain' (click)="toggleNavbar()"><span>Blockchain Development</span></a></li>
                                                  <li><a routerLink='/us/our-services/game-development' (click)="toggleNavbar()"><span>Game Development</span></a></li>
                                                  <li><a routerLink='/us/our-services/ar-vr-mr' (click)="toggleNavbar()"><span>AR/VR/MR</span></a></li>
                                                  <li><a routerLink='/us/our-services/web-development' (click)="toggleNavbar()"><span>Web Development</span></a></li>
                                                  <li><a routerLink='/us/our-services/ai-ml' (click)="toggleNavbar()"><span>AI/ML & Data Sciences</span></a></li>
                                                  <li><a routerLink='/us/our-services/iot' (click)="toggleNavbar()"><span>IoT & Robotics</span></a></li>
                                                  <li><a routerLink='/us/our-services/web-3' (click)="toggleNavbar()"><span>Web 3.0 Development</span></a></li>
                                    
                                               </ul>
                                            </div>
                                         </div>
                                      </div>
                                      <div class="card single-item">
                                         <div class="card-header item-nav subthirdmenu">
                                            <a data-toggle="collapse" class="subthirdmenu" data-parent="#accordion" (click)="togglefourthSubBar()">Design</a>
                                         </div>
                                         <div class="collapse item-content-wrap" [ngClass]="{ 'show': navfourthsubbar }">
                                            <div class="card-body item-content">
                                               <ul class="dropdown-menu-col-1 subhead">
                                                  <li><a routerLink='/us/our-services/ui-ux' (click)="toggleNavbar()"><span>UI/UX</span></a></li>
                                                  <li><a routerLink='/us/our-services/graphic-design' (click)="toggleNavbar()"><span>Graphic Design</span></a></li>
                                                  <li><a routerLink='/us/our-services/explainer-video' (click)="toggleNavbar()"><span>Explainer Video</span></a></li>
                                                  <li><a routerLink='/us/our-services/3d-art' (click)="toggleNavbar()"><span>3D ART</span></a></li>
                                               </ul>
                                            </div>
                                         </div>
                                      </div>

                                      <div class="card single-item">
                                       <div class="card-header item-nav subthirdmenu">
                                          <a data-toggle="collapse" class="subthirdmenu" data-parent="#accordion" (click)="toggleMvpSubBar()">Product</a>
                                       </div>
                                       <div class="collapse item-content-wrap" [ngClass]="{ 'show': navMvpsubbar }">
                                          <div class="card-body item-content">
                                             <ul class="dropdown-menu-col-1 subhead">
                                                <li><a routerLink='/us/our-services/mvp-development' (click)="toggleNavbar()"><span>MVP Development</span></a></li>
                                             </ul>
                                          </div>
                                       </div>
                                    </div>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </li>
                       <li class="navmre">
                          <a routerLink='/us/portfolio' (click)="toggleNavbar()"><span>Our-Work</span></a>
                       </li>
                       <!-- <li class="navmre">
                          <a href='https://monkhub.com/blog/'><span>Blog</span></a>
                       </li> -->
                       <li class="navmre"><a routerLink='/us/about-us' (click)="toggleNavbar()"><span>About Us</span></a></li>
                       <li class="navmre">
                        <a routerLink='/us/careers' (click)="toggleNavbar()"><span>Careers</span></a>
                       </li>
                       <li class="navmre">
                        <!-- <a routerLink='/us/blockchain-cryptodaily' (click)="toggleNavbar()"><span>Cryptodaily</span></a> -->
                       </li>
                    </ul>
                    <!-- <div class="row">
                     <div class="col-lg-12">
                        <a routerLink='/us/contact-us'  class="btn_head" (click)="toggleNavbar()"><span>Contact Us</span></a>
                     </div>

                    </div> -->
                    <div class="others-options d-flex align-items-center">
                     <a routerLink='/us/contact-us'  class="btn_head" (click)="toggleNavbar()"><span>Contact Us</span></a>
                      
                    </div>
                 </div>
              </nav>
           </div>
        </div>
        <!-- <div class="mean-bar">
           <div class="mobile-menu-nav-back"><a class="logo-mobile" routerLink='/in'><img src="../../assets/logo/logo@1xblue.png" alt="logo" class="img-fluid"></a></div>
           <a href="#nav" class="meanmenu-reveal meanclose" style="right: 0px; left: auto; text-align: center; text-indent: 0px; font-size: 18px;">X</a> -->
        <!-- <nav class="mean-nav">
           <ul style="">
            <li>
              <a routerLink='/in'>Home</a>
              </li>
              <li>
                 <a href="#">Services</a>
                 <ul class="dropdown-menu-col-1" style="display: none;">
                    <li><a href="service-layout1.html"><span>Services -01</span></a></li>
                    <li><a href="service-layout2.html"><span>Services -02</span></a></li>
                    <li><a href="service-details.html"><span>Services Details</span></a></li>
                 </ul>
                 <a class="mean-expand" href="#" style="font-size: 18px">+</a>
              </li>
              <li>
                 <a href="#">Portfolio</a>
                 <ul class="dropdown-menu-col-1" style="display: none;">
                    <li><a href="gallery-grid-2-column.html"><span>Grid 2 Column</span></a></li>
                    <li><a href="gallery-grid-3-column.html"><span>Grid 3 Column</span></a></li>
                    <li><a href="gallery-grid-full-width-3-column.html"><span>Grid Full Width 3 Column</span></a></li>
                    <li><a href="gallery-grid-full-width-4-column.html"><span>Grid Full Width 4 Column</span></a></li>
                    <li><a href="gallery-masonry-2-column.html"><span>Masonry 2 Column</span></a></li>
                    <li><a href="gallery-masonry-3-column.html"><span>Masonry 3 Column</span></a></li>
                    <li><a href="gallery-masonry-full-width-3-column.html"><span>Masonry Full Width 3 Column</span></a></li>
                    <li><a href="gallery-details.html"><span>Portfolio Details 1</span></a></li>
                    <li><a href="gallery-details2.html"><span>Portfolio Details 2</span></a></li>
                    <li><a href="gallery-details3.html"><span>Portfolio Details 3</span></a></li>
                 </ul>
                 <a class="mean-expand" href="#" style="font-size: 18px">+</a>
              </li>
              <li>
                 <a href="#">Pages</a>
                 <ul class="dropdown-menu-col-1" style="display: none;">
                    <li><a href="about.html"><span>About Us 1</span></a></li>
                    <li><a href="about2.html"><span>About Us 2</span></a></li>
                    <li><a href="pricing-layout1.html"><span>Our Pricing 1</span></a></li>
                    <li><a href="pricing-layout2.html"><span>Our Pricing 2</span></a></li>
                    <li><a href="team.html"><span>Team Page</span></a></li>
                    <li><a href="404.html"><span>404 Page</span></a></li>
                    <li><a href="faq.html"><span>FAQ Page</span></a></li>
                    <li><a href="coming-soon.html"><span>Coming Soon</span></a></li>
                    <li class="has-child-second-level">
                       <a href="#"><span>First Level</span></a>
                       <ul class="second-level" style="display: none;">
                          <li><a routerLink='/in'><span>Second Level 1</span></a></li>
                          <li><a routerLink='/in'><span>Second Level 2</span></a></li>
                          <li class="has-child-third-level">
                             <a href="#"><span>Second Level 3</span></a>
                             <ul class="third-level" style="display: none;">
                                <li><a routerLink='/in'><span>Third Level 1</span></a></li>
                                <li><a routerLink='/in'><span>Third Level 2</span></a></li>
                             </ul>
                             <a class="mean-expand" href="#" style="font-size: 18px">+</a>
                          </li>
                       </ul>
                       <a class="mean-expand" href="#" style="font-size: 18px">+</a>
                    </li>
                 </ul>
                 <a class="mean-expand" href="#" style="font-size: 18px">+</a>
              </li>
              <li>
                 <a href="#">Blog</a>
                 <ul class="dropdown-menu-col-1" style="display: none;">
                    <li><a href="blog-grid.html"><span>Blog Grid</span></a></li>
                    <li><a href="blog-list.html"><span>Blog List </span></a></li>
                    <li><a href="blog-masonry.html"><span>Blog Masonry</span></a></li>
                    <li><a href="blog-details.html"><span>Blog Single</span></a></li>
                 </ul>
                 <a class="mean-expand" href="#" style="font-size: 18px">+</a>
              </li>
              <li class="mean-last">
                 <a href="contact.html"><span>Contact</span></a>
              </li>
           </ul>
           </nav> -->
        <!-- </div> -->
     </div>
  </div>
</header>
