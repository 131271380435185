import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactUsComponent } from './app-in/contact-us/contact-us/contact-us.component';

import { AboutUsComponent } from './app-in/about-us/about-us.component';
import { HomeUsComponent } from './us/home-us/home-us.component';
import { BlockchainUsComponent } from './us/our-services/blockchain-us/blockchain-us.component';
import { AppDevelopmentUsComponent } from './us/our-services/app-development-us/app-development-us.component';
import { WebDevelopmentUsComponent } from './us/our-services/web-development-us/web-development-us.component';
import { GameDevelopmentUsComponent } from './us/our-services/game-development-us/game-development-us.component';
import { AiMlUsComponent } from './us/our-services/ai-ml-us/ai-ml-us.component';
import { BlockchainDefiUsComponent } from './us/our-services/blockchain-services/blockchain-defi-us/blockchain-defi-us.component';
import { BlockchainEthereumUsComponent } from './us/our-services/blockchain-services/blockchain-ethereum-us/blockchain-ethereum-us.component';
import { BlockchainExchangeUsComponent } from './us/our-services/blockchain-services/blockchain-exchange-us/blockchain-exchange-us.component';
import { BlockchainGameUsComponent } from './us/our-services/blockchain-services/blockchain-game-us/blockchain-game-us.component';
import { BlockchainIcoUsComponent } from './us/our-services/blockchain-services/blockchain-ico-us/blockchain-ico-us.component';
import { BlockchainNftUsComponent } from './us/our-services/blockchain-services/blockchain-nft-us/blockchain-nft-us.component';
import { BlockchainSmartContractAuditUsComponent } from './us/our-services/blockchain-services/blockchain-smart-contract-audit-us/blockchain-smart-contract-audit-us.component';
import { BlockchainStableCoinUsComponent } from './us/our-services/blockchain-services/blockchain-stable-coin-us/blockchain-stable-coin-us.component';
import { BlockchainTokenizationUsComponent } from './us/our-services/blockchain-services/blockchain-tokenization-us/blockchain-tokenization-us.component';
import { BlockchainWhitePaperUsComponent } from './us/our-services/blockchain-services/blockchain-white-paper-us/blockchain-white-paper-us.component';
import { BlockchainWalletUsComponent } from './us/our-services/blockchain-services/blockchain-wallet-us/blockchain-wallet-us.component';
import { BlockchainStoUsComponent } from './us/our-services/blockchain-services/blockchain-sto-us/blockchain-sto-us.component';
import { ContactUsUsComponent } from './us/contact-us/contact-us-us/contact-us-us.component';
import { GetFreeQuoteUsComponent } from './us/contact-us/get-free-quote-us/get-free-quote-us.component';
import { NdaDetailsUsComponent } from './us/contact-us/nda-details-us/nda-details-us.component';
import { ProjectDetailsUsComponent } from './us/contact-us/project-details-us/project-details-us.component';
import { AboutUsUsComponent } from './us/about-us-us/about-us-us.component';
import { OurWorkUsComponent } from './us/our-work-us/our-work-us.component';
import { ThankYouUsComponent } from './us/thank-you-us/thank-you-us.component';
import { ArVrMrUsComponent } from './us/our-services/ar-vr-mr-us/ar-vr-mr-us.component';
import { ExplainerVideoUsComponent } from './us/our-services/explainer-video-us/explainer-video-us.component';
import { GraphicDesignUsComponent } from './us/our-services/graphic-design-us/graphic-design-us.component';
import { IotUsComponent } from './us/our-services/iot-us/iot-us.component';
import { UiUxUsComponent } from './us/our-services/ui-ux-us/ui-ux-us.component';
import { P404Component } from './error/404.component';
import { NftExchangePlatformUsComponent } from './us/our-services/blockchain-services/blockchain-nft-subservices/nft-exchange-platform-us/nft-exchange-platform-us.component';
import { NftMarketplaceDevelopmentUsComponent } from './us/our-services/blockchain-services/blockchain-nft-subservices/nft-marketplace-development-us/nft-marketplace-development-us.component';

import { UnityGameDevelopmentUsComponent } from './us/our-services/game-development-services/unity-game-development-us/unity-game-development-us.component';
import { CareersComponent } from './app-in/careers/careers.component';
import { CareersUsComponent } from './us/careers-us/careers-us.component';
// import { CryptodailyUsComponent } from './us/cryptodaily-us/cryptodaily-us.component';
import { PrivacyPolicyComponent } from './app-in/privacy-policy/privacy-policy.component';
import { IosAppDevelopmentComponent } from './app-in/services-in/app-development/ios-app-development/ios-app-development.component';
import { HybridAppDevelopmentComponent } from './app-in/services-in/app-development/hybrid-app-development/hybrid-app-development.component';
import { AndroidAppDevelopmentUsComponent } from './us/our-services/app-development-us/android-app-development-us/android-app-development-us.component';
import { IosAppDevelopmentUsComponent } from './us/our-services/app-development-us/ios-app-development-us/ios-app-development-us.component';
import { ReactAppDevelopmentComponent } from './app-in/services-in/app-development/react-app-development/react-app-development.component';
import { FlutterAppDevelopmentComponent } from './app-in/services-in/app-development/flutter-app-development/flutter-app-development.component';
import { AppUiUxDesignAndDevelopmentComponent } from './app-in/services-in/app-development/app-ui-ux-design-and-development/app-ui-ux-design-and-development.component';



const routes: Routes = [
  { 
    path: 'us', 
    loadChildren: () => import(`./app-in/app-in.module`).then(
      module => module.AppInModule
    )
  },
  { 
    path: 'us-old', 
    loadChildren: () => import(`./lazy-loading/lazy-loading.module`).then(
      module => module.LazyLoadingModule
    )
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
// in to us redirection start

{
  path:'', redirectTo:'us',
  pathMatch:'full'
},

  {
    path:'in/our-services/blockchain', redirectTo:'us/our-services/blockchain',
    pathMatch:'full'
  },

  {
    path:'in/our-services/app-development', redirectTo:'us/our-services/app-development',
    pathMatch:'full'
  },
  {
    path:'in/our-services/metaverse', redirectTo:'us/our-services/metaverse',
    pathMatch:'full'
  },
  {
    path:'in/our-services/game-development', redirectTo:'us/our-services/game-development',
    pathMatch:'full'
  },

  {
    path:'in/our-services/ar-vr-mr', redirectTo:'us/our-services/ar-vr-mr',
    pathMatch:'full'
  },

  {
    path:'in/our-services/web-development', redirectTo:'us/our-services/web-development',
    pathMatch:'full'
  },

  {
    path:'in/our-services/ai-ml', redirectTo:'us/our-services/ai-ml',
    pathMatch:'full'
  },

  {
    path:'in/our-services/iot', redirectTo:'us/our-services/iot',
    pathMatch:'full'
  },

  {
    path:'in/our-services/ui-ux', redirectTo:'us/our-services/ui-ux',
    pathMatch:'full'
  },

  {
    path:'in/our-services/graphic-design', redirectTo:'us/our-services/graphic-design',
    pathMatch:'full'
  },

  {
    path:'in/our-services/explainer-video', redirectTo:'us/our-services/explainer-video',
    pathMatch:'full'
  },
  {
    path:'in', redirectTo:'us',
    pathMatch:'full'
  },

  {
    path:'in/our-work', redirectTo:'us/our-work',
    pathMatch:'full'
  },

  {
    path:'in/about-us', redirectTo:'us/about-us',
    pathMatch:'full'
  },

  {
    path:'in/careers', redirectTo:'us/careers',
    pathMatch:'full'
  },

  {
    path:'in/blockchain-cryptodaily', redirectTo:'us/blockchain-cryptodaily',
    pathMatch:'full'
  },
  {
    path:'in/contact-us' , redirectTo:'us/contact-us',
    pathMatch:'full'
  },
  {
    path:'in/privacy-policy' , redirectTo:'us/privacy-policy',
    pathMatch:'full'
  },

  {
    path:'in/our-services/app-development/ios-app-development', redirectTo:'us/our-services/app-development/ios-app-development',
    pathMatch:'full'
  },

  {
    path:'in/our-services/app-development/android-app-development', redirectTo:'us/our-services/app-development/android-app-development',
    pathMatch:'full'
  },

  {
    path:'in/our-services/app-development/flutter-app-development', redirectTo:'us/our-services/app-development/flutter-app-development',
    pathMatch:'full'
  },

  {
    path:'in/our-services/app-development/react-app-development', redirectTo:'us/our-services/app-development/react-app-development',
    pathMatch:'full'
  },

  {
    path:'in/our-services/app-development/app-maintenance-and-support', redirectTo:'us/our-services/app-development/app-maintenance-and-support',
    pathMatch:'full'
  },

  {
    path:'in/our-services/app-development/app-ui-us-design-and-development', redirectTo:'us/our-services/app-development/app-ui-us-design-and-development',
    pathMatch:'full'
  },

  {
    path:'in/our-services/app-development/hybrid-app-development', redirectTo:'us/our-services/app-development/hybrid-app-development',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-defi', redirectTo:'us/our-services/blockchain-services/blockchain-defi',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-sto', redirectTo:'us/our-services/blockchain-services/blockchain-sto',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-exchange', redirectTo:'us/our-services/blockchain-services/blockchain-exchange',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-nft', redirectTo:'us/our-services/blockchain-services/blockchain-nft',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-stable-coin', redirectTo:'us/our-services/blockchain-services/blockchain-stable-coin',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-ico', redirectTo:'us/our-services/blockchain-services/blockchain-ico',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-tokenization', redirectTo:'us/our-services/blockchain-services/blockchain-tokenization',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-wallet', redirectTo:'us/our-services/blockchain-services/blockchain-wallet',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-whitepaper', redirectTo:'us/our-services/blockchain-services/blockchain-whitepaper',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-ethereum', redirectTo:'us/our-services/blockchain-services/blockchain-ethereum',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-smart-contract-audit', redirectTo:'us/our-services/blockchain-services/blockchain-smart-contract-audit',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-game', redirectTo:'us/our-services/blockchain-services/blockchain-game',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-nft-exchange-platforms', redirectTo:'us/our-services/blockchain-services/blockchain-nft-exchange-platforms',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-game', redirectTo:'us/our-services/blockchain-services/blockchain-game',
    pathMatch:'full'
  },

  {
    path:'in/our-services/blockchain-services/blockchain-nft-marketplace-development', redirectTo:'us/our-services/blockchain-services/blockchain-nft-marketplace-development',
    pathMatch:'full'
  },


  {
    path:'in/portfolio', redirectTo:'us/portfolio',
    pathMatch:'full'
  },

  {
    path:'in/project-details/:id' , redirectTo:'us/project-details/:id',
    pathMatch:'full'
  },
  {
    path:'in/nda-details/:id' , redirectTo:'us/nda-details/:id ',
    pathMatch:'full'
  },
 







  // in to us redirection end



  {
    path:'in/services/games', redirectTo:'in/our-services/game-development',
    pathMatch:'full'
  },
  {
    path:'in/services/apps-development' , redirectTo:'in/our-services/app-development',
    pathMatch:'full'
  },
  {
    path:'our-services/apps-development' , redirectTo:'in/our-services/app-development',
    pathMatch:'full'
  },
  {
    path:'in/services/blockchain' , redirectTo:'in/our-services/blockchain',
    pathMatch:'full'
  },
  {
    path:'in/services/web-development' , redirectTo:'in/our-services/web-development',
    pathMatch:'full'
  },
  {
    path:'blockchain' , redirectTo:'in/our-services/blockchain',
    pathMatch:'full'
  },
  {
    path:'in/services/apps-development' , redirectTo:'in/our-services/app-development',
    pathMatch:'full'
  },
  {
    path:'in/services/blockchain/blockchain-exchange' , redirectTo:'in/our-services/blockchain-services/blockchain-exchange',
    pathMatch:'full'
  },
  {
    path:'blockchain-exchange' , redirectTo:'in/our-services/blockchain-services/blockchain-exchange',
    pathMatch:'full'
  },
  {
    path:'in/services/apps-development' , redirectTo:'in/our-services/app-development',
    pathMatch:'full'
  },
  {
    path:'in/services/blockchain/blockchain-wallet-development' , redirectTo:'in/our-services/blockchain-services/blockchain-wallet',
    pathMatch:'full'
  },
  {
    path:'in/services/blockchain/blockchain-whitepaper' , redirectTo:'in/our-services/blockchain-services/blockchain-whitepaper',
    pathMatch:'full'
  },
  {
    path:'in/services/blockchain/blockchain-sto' , redirectTo:'in/our-services/blockchain-services/blockchain-sto',
    pathMatch:'full'
  },
  {
    path:'in/services/blockchain/blockchain-nft' , redirectTo:'in/our-services/blockchain-services/blockchain-nft',
    pathMatch:'full'
  },
  {
    path:'in/services/blockchain/blockchain-game-development' , redirectTo:'in/our-services/blockchain-services/blockchain-game',
    pathMatch:'full'
  },
  {
    path:'in/services/blockchain/blockchain-defi' , redirectTo:'in/our-services/blockchain-services/blockchain-defi',
    pathMatch:'full'
  },
  {
    path:'in/services/blockchain/blockchain-ethereum-development' , redirectTo:'in/our-services/blockchain-services/blockchain-ethereum',
    pathMatch:'full'
  },
  {
    path:'in/services/blockchain/blockchain-ico' , redirectTo:'in/our-services/blockchain-services/blockchain-ico',
    pathMatch:'full'
  },
  {
    path:'blockchain-tokenization' , redirectTo:'in/our-services/blockchain-services/blockchain-tokenization',
    pathMatch:'full'
  },
  {
    path:'in/services/ai-ml' , redirectTo:'in/our-services/ai-ml',
    pathMatch:'full'
  },
  {
    path:'in/services/iot-robotics' , redirectTo:'in/our-services/iot',
    pathMatch:'full'
  },
  {
    path:'iot-robotics' , redirectTo:'in/our-services/iot',
    pathMatch:'full'
  },
  {
    path:'in/services/ui-ux' , redirectTo:'in/our-services/ui-ux',
    pathMatch:'full'
  },
  {
    path:'in/services/ar-vr-mr' , redirectTo:'in/our-services/ar-vr-mr',
    pathMatch:'full'
  },
  {
    path:'ar-vr-mr' , redirectTo:'in/our-services/ar-vr-mr',
    pathMatch:'full'
  },
  {
    path:'in/services/graphic-design' , redirectTo:'in/our-services/graphic-design',
    pathMatch:'full'
  },
  {
    path:'in/services/explainer-video' , redirectTo:'in/our-services/explainer-video',
    pathMatch:'full'
  },
  {
    path:'about' , redirectTo:'in/about-us',
    pathMatch:'full'
  },
  {
    path:'contact-us' , redirectTo:'in/contact-us',
    pathMatch:'full'
  },


 
  
  // {
  //   path: 'in',

  //   children: [
  //    {
  //       path: 'our-services',
  //       children: [             
  //         { path: 'app-development', component: AppDevelopmentComponent,
  //         },
  //         {
  //           path:'app-development',
  //           children: [
  //             { path: 'android-app-development', component: AndroidAppDevelopmentComponent },
  //             { path: 'ios-app-development', component: IosAppDevelopmentComponent },
  //              { path: 'hybrid-app-development', component: HybridAppDevelopmentComponent },
  //              { path: 'react-app-development', component: ReactAppDevelopmentComponent },
  //              { path: 'flutter-app-development', component: FlutterAppDevelopmentComponent },
  //              { path: 'app-maintenance-and-support', component: AppMaintenanceAndSupportComponent },
  //              { path: 'app-ui-us-design-and-development', component: AppUiUxDesignAndDevelopmentComponent }
  //           ]
  //         },
  //         { path: 'metaverse', component: MetaverseComponent },
  //         { path: 'web-development', component: WebDevelopmentComponent },
  //         { path: 'game-development', component: GameDevelopmentComponent },
  //         { path: 'blockchain', component: BlockchainComponent },
  //         { path: 'ar-vr-mr', component: ArVrMrComponent },
  //         { path: 'ui-ux', component: UiUxComponent },
  //         { path: 'ai-ml', component: AiMlComponent },
  //         { path: 'iot', component: IotComponent },
  //         { path: 'graphic-design', component: GraphicDesignComponent },
  //         { path: '3d-art', component: ThreeDArtComponent },
  //         { path: 'explainer-video', component: ExplainerVideoComponent },
  //         {
  //           path: 'blockchain-services',
  //           children: [
  //             { path: 'blockchain-defi', component: BlockchainDefiComponent },
  //             { path: 'blockchain-sto', component: BlockchainStoComponent },
  //             { path: 'blockchain-exchange', component: BlockchainExchangeComponent },
  //             { path: 'blockchain-nft', component: BlockchainNftComponent },
  //             { path: 'blockchain-stable-coin', component: BlockchainStableCoinComponent },
  //             { path: 'blockchain-ico', component: BlockchainIcoComponent },
  //             { path: 'blockchain-tokenization', component: BlockchainTokenizationComponent },
  //             { path: 'blockchain-wallet', component: BlockchainWalletComponent },
  //             { path: 'blockchain-whitepaper', component: BlockchainWhitePaperComponent },
  //             { path: 'blockchain-ethereum', component: BlockchainEthereumComponent },
  //             { path: 'blockchain-smart-contract-audit', component: BlockchainSmartContractAuditComponent },
  //             { path: 'blockchain-game', component: BlockchainGameComponent },
  //             {
  //               path:'',
  //               children: [
  //                 { path:'blockchain-nft-exchange-platforms', component: NftExchangePlatformComponent },
  //                 { path:'blockchain-nft-marketplace-development', component: NftMarketplaceDevelopmentComponent}
  //               ]
  //             }
  //           ]
  //         },
  //         { path: 'game-development-services',
  //           children: [
  //             { path: 'unity-game-development', component: UnityGameDevelopmentComponent}
  //           ]
  //         }
  //       ]
  //     },
  //     { path: '', component: HomeComponent },
  //     
  //     
  //     
  //    ,
  //    
  //    
  //    
  //     
  //     { path:'blockchain-cryptodaily', component:CryptodailyComponent},
  //     { path: 'privacy-policy' , component: PrivacyPolicyComponent },

  //   ]
  // },

  // { path: '', redirectTo: '/in', pathMatch: 'full' },

  // { path: '', component: HomeComponent },
  // { path: '', redirectTo: '/us/home',pathMatch: 'full' },
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full',
  // },
  { path: '**', component: P404Component },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled', scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
