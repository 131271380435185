<div id="wrapper" class="wrapper">
  <a href="#main_content" data-type="section-switch" class="return-to-top">
    <i class="fas fa-angle-double-up"></i>
  </a>

  <div id="main_content">
    <!--=====================================-->
    <section class="main-banner-wrap-layout1 bg-gradient-layout1 ">
      <div class="container">
        <div class="text-center" style="color: #fff;">
          <h2 style="color: #fff;">Get Free Quote</h2>
          <p style="color: #fff;font-size: 18px;">Please fill the form and our engagement manager will get in touch with
            you, shortly!</p>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 col-12 order-lg-2 d-flex align-items-center justify-content-center">
              <div class="">
                <div class="main-banner-box-layout1">
                  <div class="">
                    <img src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1696837972942-Lets_talk.png" class="img-wd"
                      alt="Illustration">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 order-lg-1 col-12 compress-left-side d-flex align-items-center">
              <div class="main-banner-box-layout1">
                <div class="contact-wrap-layout1 forbox">
                  <div class="contact-box-layout1 ">
                    <form autocomplete="off" (ngSubmit)="projectDetailsPost()" [formGroup]="mainForm">
                      <div class="row">
                        <div class="col-lg-12 d-none" >
                        <div class="form-group">
                    
                            <input type="text" class="form-control" id="formName" placeholder="Form Name" name="formName" formControlName="formName" readonly>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                            <input autocomplete="false" type="text" class="form-control" id="name" placeholder="Name *" name="name" formControlName="name" data-toggle="tooltip" data-trigger="hover" data-placement="top" required data-original-title="" title="">
                          <!--<div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                              <div *ngIf="name">Name is required</div>
                          </div> -->
                          <div class="form-input-error" *ngIf="submitted && f['name'].invalid">
                            <div *ngIf="f['name'].errors && f['name'].errors['required']">Name is required</div>
                          </div>    
                        </div>
                      </div>
                    
                        <div class="col-lg-12">
                        <div class="form-group">
                            <input autocomplete="false" type="email" class="form-control" placeholder="Email *" name="email" 
                            data-toggle="tooltip" data-trigger="hover" data-placement="top" required data-original-title="" title=""
                            formControlName="email"
                            pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                            [email]="true">
                            <!--<div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                              <div *ngIf="email">Email is required</div>
                            </div>-->
                            <div class="form-input-error" *ngIf="submitted && f['email'].invalid">
                              <div *ngIf="f['email'].errors && f['email'].errors['required']" >Email is required</div>
                              <!--<div *ngIf="f['email'].errors?.['pattern']" >Enter A Valid Email</div>-->
                            </div>
                        </div>  
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="tele">
                           <ngx-intl-tel-input
                            [cssClass]="'form-control'"
                            [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="false" 
                            [enablePlaceholder]="true" 
                            [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                            [selectedCountryISO]="CountryISO.UnitedStates" 
                            [selectFirstCountry]="selectFirstCountry"
                            [phoneValidation]="true" 
                            [separateDialCode]="separateDialCode"
                            (ngModelChange)="onChange($event)"
                            name="phone"
                            formControlName="phone"
                            required>
                          </ngx-intl-tel-input>
                          </div>
                        
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="form-group">
                          <select name="category" class="form-control category"  [(ngModel)]="getQuoteDetails.category"  aria-required="true" formControlName="category"
                           aria-invalid="false">
                            <option value="" selected>Choose Your Category</option>
                            <option value="App Development">App Development</option>
                            <option value="Web Development">Web Development</option>
                            <option value="Blockchain Development">Blockchain Development</option>
                            <option value="Game Development">Game Development</option>
                            <option value="Metaverse Development">Metaverse Development</option>
                            <option value="AR / VR / MR">AR / VR / MR</option>
                            <option value="AI &amp; ML">AI &amp; ML</option>
                            <option value="Customs Application">Customs Application</option>
                            <option value="IoT &amp; Robotics">IoT &amp; Robotics</option>
                            <option value="UI &amp; UX">UI &amp; UX</option>
                            <!--<option value="Explainer Video">Explainer Video</option>
                            <option value="Graphic Design">Graphic Design</option>
                            <option value="Branding">Branding</option>
                            <option value="Logo Design">Logo Design</option>
                            <option value="Growth &amp; Digital Marketing">Growth &amp; Digital Marketing</option>
                            <option value="Native Advertising">Native Advertising</option>
                            <option value="Pay Per Click">Pay Per Click</option>-->
                            <option value="I'm not sure">I'm not sure</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                      </div>
                      </div>
                      <div class="form-actions">
                          <button  click="return gtag_report_conversion(`https://www.monkhub.com/us/our-services/ui-ux`)" type="submit" class="btn_mythree" style="width: 100%;cursor: pointer;">
                            Submit <i class="fa fa-spinner fa-spin" *ngIf="imgloading"></i>
                          </button>
                        </div>  
                      </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<script>(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"56360281"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");</script>