import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactServiceService {
  token: any;
  // userdetails: any;
  data: any;
  constructor(private http: HttpClient) { }

  formOne(firstForm:any) {
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    return this.http.post(environment.apiurl + '/website/yourProject/save', firstForm, httpOptions);
  }

    /**
   * Project Details
   */
     projectDetails(formData:any) {
      console.log(formData);
      const httpOptions = {
          headers: new HttpHeaders({

          })
      };
      return this.http.post(environment.apiurl + '/website/great/save', formData, httpOptions);
  }

  companyDetails(firstForm:any) {
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    // return this.http.post(environment.apiurl + '/thankyou/save/company/details', firstForm, httpOptions);
    return this.http.post(environment.apiurl + '/website/contactUs/save', firstForm, httpOptions);
  }

  contactUss(firstForm:any) {
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    return this.http.post(environment.apiurl + '/app/save/lead', firstForm, httpOptions);
  }

      /**
   * Get Free Quote Details
   */
       getFreeQuoteDetails(formData:any) {
        console.log(formData);
        const httpOptions = {
            headers: new HttpHeaders({

            })
        };

        return this.http.post(environment.apiurl + '/thankyou/save/free/quote', formData, httpOptions);
    }
}
