import { Component , OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';

import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  name = 'Get Current Url Route Demo';
  currentRoute: string ='';
  mydata: any;
  headershow:any;
  footershow:any;
  headerin: boolean = false;
  headerus: boolean = false;
  footerin: boolean = false;
  footerus: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute){
    }

    private destroy$ = new Subject();

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
  ).subscribe(res => this.urlDecode(res));
  }

  urlDecode(res: any){
 this.mydata = res;
var parts = this.mydata.url.split('/');
var answer = parts[1];
this.forheader(answer);
this.forfooter(answer);
  }

  forheader(answer:any){
this.headershow = answer;
this.headerin = true;
if(this.headershow == 'in'){
this.headerin = true;
this.headerus =false;
}
else if(this.headershow == ''){
  this.headerin = true;
  this.headerus =false;
} else if(this.headershow == 'us'){
  this.headerin = true;
  this.headerus =false;
}
  }
  forfooter(answer:any){
    this.footershow = answer;
    this.footerin = true;
    if(this.headershow == 'in'){
    this.footerin = true;
    this.footerus =false;
    }
    else if(this.footershow == ''){
      this.footerin = true;
      this.footerus =false;
    } else if(this.footershow == 'us'){
      this.footerin = false;
      this.footerus =true;
    }
  }
  title = 'monkhubangular';

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
   }
}
