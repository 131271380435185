import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Contact } from '../../../models/contact-model/contact.model';
import { ContactServiceService } from '../../../services/contact-service/contact-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-nda-details',
  templateUrl: './nda-details.component.html',
  styleUrls: ['./nda-details.component.scss']
})
export class NdaDetailsComponent implements OnInit {
  companyDetails = this.contactUsModel.companyDetails;
  imgloading: boolean =false;
  successMessage: string = '';
  formNames = this.route.snapshot.params.id;
  constructor( private router: Router,
    private route: ActivatedRoute,   private contactUsModel: Contact,
    private contactService: ContactServiceService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
     window.scrollTo(0, 0);
    this.animation();
    this.companyDetails.formName = this.formNames;
    this.getCompaignData();
  }

  getCompaignData(){
    this.route.queryParams.subscribe(res => {
      if(res.utm_source != undefined){
        this.companyDetails.utmSource = res.utm_source;
      }
      if(res.utm_medium != undefined){
        this.companyDetails.utmMedium = res.utm_medium;
      }
      if(res.utm_campaign != undefined){
        this.companyDetails.utmCampaign = res.utm_campaign;
      }
      if(res.utmTerm != undefined){
        this.companyDetails.utmTerm = res.utm_term;
      }
      if(res.utm_content != undefined){
        this.companyDetails.utmContent = res.utm_content;
      }
    })
  }

  animation(){
    if (!!window.IntersectionObserver) {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add("active-animation");
            //entry.target.src = entry.target.dataset.src;
            observer.unobserve(entry.target);
          }
        });
      }, {
        rootMargin: "0px 0px -100px 0px"
      });
      document.querySelectorAll('.has-animation').forEach(block => {
        observer.observe(block)
      });
    } else {
      document.querySelectorAll('.has-animation').forEach(block => {
        block.classList.remove('has-animation')
      });
    }
  }
  contactForm() {
    this.imgloading = true;
    if("personDetails" in localStorage){
      const perDetails = JSON.parse(localStorage.getItem('personDetails') || '{}');
      this.companyDetails.consultationId = perDetails.consultationId;
   } else {
    this.companyDetails.consultationId = 0;
   }
    
    this.contactService.companyDetails(this.companyDetails).subscribe(
      (data: any) => {
        this.successMessage = 'Thanks for sharing the details'
        this.toastrService.success(this.successMessage, 'Success', {
          timeOut: 120,
          progressBar: true,
          closeButton: true,
          easeTime: 200,
          extendedTimeOut: 500,
        });
        this.imgloading = false;
        this.pageToRedirect();
      },
      error => {
        console.log(error);
        this.imgloading = false;
      }
    );
  }
  pageToRedirect() {
    const id = this.companyDetails.formName
    this.router.navigate(['/us/thank-you/']);
  }

}
