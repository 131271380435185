import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientInComponent } from './client-in/client-in.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { IndWeServeComponent } from './ind-we-serve/ind-we-serve.component';
import { TrustedBrandsComponent } from './trusted-brands/trusted-brands.component';
import { BadgesInComponent } from './badges-in/badges-in.component';
import { ClutchComponent } from './clutch/clutch.component';
import { OurWorkComponent } from './our-work/our-work.component';
import { ChooseUsComponent } from './choose-us/choose-us.component';
import { WebinarComponent } from './webinar/webinar.component';




@NgModule({
  declarations: [
    ClientInComponent,
    IndWeServeComponent,
    TrustedBrandsComponent,
    BadgesInComponent,
    ClutchComponent,
    OurWorkComponent,
    ChooseUsComponent,
    WebinarComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
  ],
    exports: [
    ClientInComponent,
    IndWeServeComponent,
    TrustedBrandsComponent,
    BadgesInComponent,
    ClutchComponent,
    OurWorkComponent,
    ChooseUsComponent
  ],
})
export class SharedModule { }
