<div id="wrapper" class="wrapper">
  <a href="#main_content" data-type="section-switch" class="return-to-top">
    <i class="fas fa-angle-double-up"></i>
  </a>

  <div id="main_content">
    <!--=====================================-->
    <section class="main-banner-wrap-layout1 bg-gradient-layout1 has-animation">
      <div class="container text-center">
        <p style="color: #fff;">Great! Your information has been received!</p>
        <h2 style="color: #fff; font-size: 26px;">Kindly schedule a meeting with one of our consultants at your available slot.</h2>
        <img src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1698394639128-Experts.png" style="width: 200px;" class="care" alt="Illustration">
        <p style="color: #fff;font-size: 16px; margin-top: 14px;">
          During this meeting, we will delve into your requirements, with our experts providing valuable
          insights into the best approach. Following this discussion, we will have gathered ample information
          about your development needs. This will enable us to create a comprehensive proposal encompassing 
          the approach, scope of work, and pricing details.</p>
      </div>
   
      <div class="container mt-5">
        <div class="row body--calendly">
          <div class="col-md-12 d-flex align-items-center">
            <div class="calendly-card p-3" style="width: 100%;">
              <!--<div class="calendly-inline-widget" data-url="https://calendly.com/monkhub_innovations?hide_landing_page_details=1&hide_gdpr_banner=1" 
      >
              </div>-->
              <div class="calendly-inline-widget" data-url="https://calendly.com/monkhub_innovations/discussion-meeting?embed_domain=monkhub.com&embed_type=Inline&hide_landing_page_details=1&hide_gdpr_banner=1&back=1&month=2023-09" 
      >
              </div>
            </div>
        
        </div>
      </div>
      </div>
   
      
    </section>
  </div>
</div>
