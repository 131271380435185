<section class="sociicons">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 col-6 text-center">
        <div>
          <a href="tel:+91%209090080015"><img width="28" height="29" src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1678191127111-Ellipse_770.png"></a>
          </div>
          <div class="">
            <a class="colaredw" href="tel:+1 234-217-3520">+1 234-217-3520</a>
          </div>
        </div>
      <div class="col-lg-2 col-6 text-center">
        <div>
          <a href="tel:+91%209090080015"><img width="28" height="29" src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1621326148878-IN.png"></a>
          </div>
          <div class="">
            <a class="colaredw" href="tel:+91 9090080015">+91 9090080015</a>
          </div>
        </div>
      <div class="col-lg-2 col-6 text-center">
        <div>
        <a href="http://connect@monkhub.com" rel="nofollow noopener" target="_blank"><img width="25" height="28" src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1621325416850-Mail-1-1.png"></a>
        </div>
        <div class="">
					<a class="colaredw" href="mailto:connect@monkhub.com">connect@monkhub.com</a>
        </div>
      </div>
      <div class="col-lg-2 col-6 text-center">
        <div>
          <a href="https://api.whatsapp.com/send?phone=919090080015&amp;text=Hello%20Monkhub" rel="nofollow noopener" target="_blank"><img width="28" height="28" src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1621326084666-Whatsapp.png"></a>
          </div>
          <div class="">
            <a class="colaredw" href="https://api.whatsapp.com/send?phone=919090080015&amp;text=Hello%20Monkhub" rel="nofollow noopener" target="_blank">+91 9090080015</a>
          </div>
      </div>
      <div class="col-lg-2 col-6 text-center">
        <div>
          <a href="https://msng.link/o/?+91%209090080015=tg" rel="nofollow noopener" target="_blank"><img width="28" height="28" src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1621326117492-Telegram.png"></a>
          </div>
          <div class="">
            <a class="colaredw" href="https://msng.link/o/?+91 9090080015=tg" rel="nofollow noopener" target="_blank">+91 9090080015</a>
          </div>
      </div>
     
      <!-- <div class="col-lg-2 col-6 text-center">
      <div>
        <a href="tel:+1%20(507)%20710-1616"><img width="28" height="28" src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1621326182021-US.png" ></a>
        </div>
        <div class="">
          <a class="colaredw" href="tel:+1 (507) 710-1616">+1 (507) 710-1616</a>
        </div>
      </div> -->
      <div class="col-lg-2 col-6 text-center">
      <div>
        <a href="https://join.skype.com/invite/uLJJgZPpELxM" rel="nofollow noopener" target="_blank"><img width="25" height="28" src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1620032851703-Skype.png"></a>
        </div>
        <div class="">
          <a href="https://join.skype.com/invite/uLJJgZPpELxM">Monkhub</a>
        </div>
      </div>
    </div>
  </div>
</section>
<footer class="footer-wrap-layout9">
  <div class="footer-top-layout9">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-6 col-12">
          <div class="footer-widgets">
            <div class="footer-logo">
              <a routerLink='/in'><img class="i" src="../../assets/logo/footerlogo.png" alt="Logo" ></a>
            </div>
            <ul class="footer-contact about-paragraph">
              <li>Monkhub is an IT firm providing services in emerging technologies like Blockchain development, AR/VR/MR, AI/ML, Game and App development. We assist businesses in design, product development and growth 
                through our services and help and building scalable models.</li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-12">
          <div class="footer-widgets">
            <h3 class="footer-widget-heading">Quick Links</h3>
            <ul class="footer-menu">
              <li><a routerLink='/us/our-services/blockchain'><span>Blockchain Development</span></a></li>
              <li><a routerLink='/us/our-services/game-development'><span>Game Development</span></a></li>
              <li><a routerLink='/us/our-services/ar-vr-mr'><span>AR/VR/MR</span></a></li>
              <li><a routerLink='/us/our-services/app-development'><span>App Development</span></a></li>
              <li><a routerLink='/us/our-services/web-development'><span>Web Development</span></a></li>
              <li><a routerLink='/us/our-services/ai-ml'><span>AI/ML</span></a></li>
              <li><a routerLink='/us/our-services/iot'><span>IoT & Robotics</span></a></li>
              <li><a routerLink='/us/our-services/ui-ux'><span>UI/UX</span></a></li>
              <li><a routerLink='/us/our-services/graphic-design'><span>Graphic Design</span></a></li>
              <li><a routerLink='/us/our-services/explainer-video'><span>Explainer Video</span></a></li>
              <li><a routerLink='/us/our-services/3d-art'><span>3D ART</span></a></li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-12">
          <div class="footer-widgets">
            <h3 class="footer-widget-heading">Additional Links</h3>
            <ul class="footer-menu">
              <li><a routerLink='/us/portfolio'><span>Our Work</span></a></li>
              <!-- <li><a href='https://monkhub.com/blog/' target="_blank"><span>Blog</span></a></li> -->
              <li><a routerLink='/us/about-us'><span>About Us</span></a></li>
              <li><a routerLink='/us/careers'><span>Careers</span></a></li>
              <li><a routerLink='/us/contact-us'><span>Contact Us</span></a></li>
              <!-- <li><a routerLink='/us/blockchain-cryptodaily'><span>Crypto Daily</span></a></li> -->
              <li><a routerLink='/us/privacy-policy'><span>Privacy Policy</span></a></li>
              <!-- rel="noopener noreferrer" target="_blank" -->

            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-12">
          <div class="footer-widgets">
            <h3 class="footer-widget-heading">Contact Info</h3>
            <ul class="footer-contact">
              <li>Unit 153 & 154, Tower B2, 
                Spaze iTech Park, Sector 49, Gurugram - 122018
                </li>
              <li><img width="16" src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1621325416850-Mail-1-1.png" alt=""> &nbsp; 
                <a class="colaredw" href="mailto:connect@monkhub.com">connect@monkhub.com</a>
              </li>
              <!-- <li><img src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1621325511748-Phone.png" alt="">&nbsp;<a class="colaredw" href="tel:+1 (507) 710-1616">+1 (507) 710-1616</a></li> -->
              <li><img src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1621325511748-Phone.png" alt="">&nbsp;USA <a class="colaredw" href="tel:+1 2342173520">+1 2342173520</a></li>
              <li><img src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1621325511748-Phone.png" alt="">&nbsp;IND <a class="colaredw" href="tel:+91 9090080015">+91 9090080015</a></li>
            </ul>
            <ul class="footer-social">
              <li><a href="https://www.linkedin.com/company/monkhub/" rel="noopener noreferrer" target="_blank"><i class="fab fa-linkedin"></i></a></li>
              <li><a href="https://angel.co/company/monkhub" rel="noopener noreferrer" target="_blank"><i class="fab fa-angellist"></i></a></li>
              <li><a href="https://www.facebook.com/monkhubinnovations/" rel="noopener noreferrer" target="_blank"><i class="fab fa-facebook"></i></a></li>
              <li><a href="https://www.instagram.com/monkhub/" rel="noopener noreferrer" target="_blank"><i class="fab fa-instagram"></i></a></li>
              <li><a href="https://twitter.com/monkhub" rel="noopener noreferrer" target="_blank"><i class="fab fa-twitter"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom-layout9">
    <div class="container">
      <div class="copy-right-wrap">
        <p class="copy-right-text">© Monkhub Innovations {{this.year}}. All Rights Reserved</p>
      </div>
    </div>
  </div>
</footer>
