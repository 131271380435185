import { Component, OnInit } from '@angular/core';
import { Contact } from '../../../models/contact-model/contact.model';
import { ContactServiceService } from '../../../services/contact-service/contact-service.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-get-free-quote',
  templateUrl: './get-free-quote.component.html',
  styleUrls: ['./get-free-quote.component.scss']
})
export class GetFreeQuoteComponent implements OnInit {
  contactDetails = this.contactUsModel.formOne;
  flagDetails = this.contactUsModel.flagModel;
  getQuoteDetails = this.contactUsModel.getFreeQuote;
  imgloading: boolean = false;
  fileData!: File;
  errorFileSize: string = '';
  formNames = this.route.snapshot.params.id;
  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.Canada, CountryISO.Norway, CountryISO.Sweden, CountryISO.India];
	number = '';
  selectFirstCountry: boolean = true;
  successMessage: string = '';
  submitted: boolean = false;
  mainForm!: FormGroup;
  utm: any;
  utmMedium: any;
  utmCampaign: any;
  utmTerm: any;
  utmContent: any;
  constructor( private router: Router,
    private route: ActivatedRoute,   private contactUsModel: Contact,
    private contactService: ContactServiceService,
    private formbuilder: FormBuilder,
    private toastrService: ToastrService) { }

    public isChecked = false;
    fileProgress(fileInput: any) {
        this.fileData = < File > fileInput.target.files[0];
        if(this.fileData.size > 1048576 ){
          this.errorFileSize = 'Congratulation!! Data Successfully Submitted'
        this.toastrService.success(this.errorFileSize, 'Success', {
          timeOut: 5000,
        });
        }
    }
    private destroy$ = new Subject();
  ngOnInit(): void {
     window.scrollTo(0, 0);
     this.getCompaignData();
    this.animation();
    // this.getQuoteDetails.category = "Choose Your Category"
    this.getQuoteDetails.formName = this.formNames;
    this.mainForm = this.formbuilder.group({
      formName: [''],
      projectBrife:[''],
      name: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      phone: ['', Validators.required],
      category: ['', Validators.required]
    });
  }

  get f(){
    return this.mainForm.controls;
  }
  animation(){
    if (!!window.IntersectionObserver) {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add("active-animation");
            //entry.target.src = entry.target.dataset.src;
            observer.unobserve(entry.target);
          }
        });
      }, {
        rootMargin: "0px 0px -100px 0px"
      });
      document.querySelectorAll('.has-animation').forEach(block => {
        observer.observe(block)
      });
    } else {
      document.querySelectorAll('.has-animation').forEach(block => {
        block.classList.remove('has-animation')
      });
    }
  }

  getCompaignData(){
    this.route.queryParams.subscribe(res => {
      if(res.utm_source != undefined){
        this.getQuoteDetails.utmSource = res.utm_source;
        localStorage.setItem('utmSource', JSON.stringify(this.getQuoteDetails.utmSource));
        this.utm = JSON.parse(localStorage.getItem('utmSource') || '{}');
      }
      if(res.utm_medium != undefined){
        this.getQuoteDetails.utmMedium = res.utm_medium;
        localStorage.setItem('utmMedium', JSON.stringify(this.getQuoteDetails.utmMedium));
        this.utmMedium = JSON.parse(localStorage.getItem('utmMedium') || '{}');
      }
      if(res.utm_campaign != undefined){
        this.getQuoteDetails.utmCampaign = res.utm_campaign;
        localStorage.setItem('utmCampaign', JSON.stringify(this.getQuoteDetails.utmCampaign));
        this.utmCampaign = JSON.parse(localStorage.getItem('utmCampaign') || '{}');
      }
      if(res.utm_term != undefined){
        this.getQuoteDetails.utmTerm = res.utm_term;
        localStorage.setItem('utmTerm', JSON.stringify(this.getQuoteDetails.utmTerm));
        this.utmTerm = JSON.parse(localStorage.getItem('utmTerm') || '{}');
      }
      if(res.utm_content != undefined){
        this.getQuoteDetails.utmContent = res.utm_content;
        localStorage.setItem('utmContent', JSON.stringify(this.getQuoteDetails.utmContent));
        this.utmContent = JSON.parse(localStorage.getItem('utmContent') || '{}');
      }
    })
  }

  projectDetailsPost() {
    this.getQuoteDetails.formName = 'Get Free Quote'
    const formData = new FormData();
    formData.append('formName', this.getQuoteDetails.formName);
    if((typeof(this.fileData)  == 'undefined') == true){
      console.log('0');
    }
    else{
      formData.append('file', this.fileData);
    }
   
    formData.append('utmSource', this.utm);
    formData.append('utmMedium', this.utmMedium);
    formData.append('utmCampaign', this.utmCampaign);
    formData.append('utmTerm', this.utmTerm);
    formData.append('utmContent', this.utmContent);
    formData.append('category', this.getQuoteDetails.category);
    formData.append('email', this.getQuoteDetails.email);
    formData.append('name', this.getQuoteDetails.name);
    formData.append('number', this.getQuoteDetails.number);
    formData.append('projectBrife', this.getQuoteDetails.projectBrife);
    formData.append('formName', this.getQuoteDetails.formName);
    this.submitted = true;

    if (this.mainForm.invalid) {
      return;
    }
    this.forFormNumber();
    this.imgloading = true;
    this.getQuoteDetails.email = this.mainForm.value.email;
    this.getQuoteDetails.name = this.mainForm.value.name;
    this.contactService.getFreeQuoteDetails(this.getQuoteDetails).subscribe(
      (data: any) => {
        this.successMessage = 'Congratulation!! Data Successfully Submitted'
        this.toastrService.success(this.successMessage, 'Success', {
          timeOut: 5000,
        });
        this.imgloading = false;
        localStorage.setItem('personDetails', JSON.stringify(data));
        // localStorage.clear();
        this.pageToRedirect();
      },
      error => {
        console.log(error);
        // localStorage.clear();
        this.errorFileSize = 'Error Coming'
        this.toastrService.error(this.errorFileSize, 'Error', {
          timeOut: 3000,
        });
      }
  );
  }
  pageToRedirect() {
    const newUrl = '/us/final-submit/thank-you';
    window.location.href = newUrl;
  }

  forFormNumber(){
    this.getQuoteDetails.number = this.flagDetails.internationalNumber;
  }

  onChange(event : any){
    this.flagDetails=event;
    console.log(this.flagDetails);
  }
  myFunction(event : any){
    console.log(event.target.value)
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
   }
}
