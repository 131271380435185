<section class="main-banner-wrap-layout1 bg-gradient-layout111 has-animation">
<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center" style="margin:15%">
      <div class="col-md-12">
        <div class="clearfix">
         <br>
          <h4 class="pt-3 mt30">Oops! You're lost.</h4>
          <p class="text-muted-1">The page you are looking for was not found.</p>
        </div>
        <!-- <div class="input-prepend input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </div>
          <input id="prependedInput" class="form-control" size="16" type="text" placeholder="What are you looking for?">
          <span class="input-group-append">
            <button class="btn btn-info" type="button">Search</button>
          </span>
        </div> -->
        <div class="mysection mt-4">
          <!-- <img src="https://mhappstorage.blob.core.windows.net/monkhubweb/images/1621330512570-Thank_you.png" alt=""> -->
          <!-- <p>Thank you for filling out the details.<br>
            Our sales team will connect with you shortly</p> -->
            <a routerLink='/us' class="getfree">Back To Home</a>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
