import { Component, OnInit } from '@angular/core';
import { Contact } from '../../../models/contact-model/contact.model';
import { ContactServiceService } from '../../../services/contact-service/contact-service.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactDetails = this.contactUsModel.contactus;
  token: any;
  formJob =true;
  succesphoto = false;
  successMessage: any;
  imgloading: boolean = false;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  emailPattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  constructor(
    private route: ActivatedRoute,
    private contactUsModel: Contact,
    private contactService: ContactServiceService,
    private router: Router,
    private toastrService: ToastrService
  ) { }
  private destroy$ = new Subject();
  ngOnInit(): void {
     window.scrollTo(0, 0);
    this.animation();
  }
  animation(){
    if (!!window.IntersectionObserver) {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add("active-animation");
            //entry.target.src = entry.target.dataset.src;
            observer.unobserve(entry.target);
          }
        });
      }, {
        rootMargin: "0px 0px -100px 0px"
      });
      document.querySelectorAll('.has-animation').forEach(block => {
        observer.observe(block)
      });
    } else {
      document.querySelectorAll('.has-animation').forEach(block => {
        block.classList.remove('has-animation')
      });
    }
  }

  contactForm() {
    this.imgloading = true;

    this.contactService.contactUss(this.contactDetails).subscribe(
      (data: any) => {
        this.successMessage = 'Congratulation!! Data Successfully Submitted'
        this.toastrService.success(this.successMessage, 'Success', {
          timeOut: 3000,
        });
        this.imgloading = false;
        this.pageToRedirect();
      },
      error => {
        console.log(error);
        this.imgloading = false;
      }
    );
  }
  pageToRedirect() {
    const id = 'Contact-us'
    // const formattedId = id.replace(/\s+/g, ''); 
    const newUrl = `/us/thank-you`;
    window.location.href = newUrl;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
   }
}
